import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
  Paper,
  Checkbox,
} from "@mui/material";

import { ButtonV1, Loader, PaginationAction, TooltipLabel } from "components";
import { Filters, TooltipAttachmentView } from "./components";
import { ErrorView, QuickFilter, TooltipView } from "../components";

import { postCall_v2 } from "services";
import { useToaster } from "hooks";
import { decimalRoundOff, moneyFormat, pascalCase, validateNull } from "utils";

import { success, error } from "constants";
import {
  BULK_SHIPMENT_CATEGORY,
  QUICK_FILTERS,
  SHIPMENT_CREATION_JODL_HEADERS,
  SHIPMENT_STATUS,
} from "../constant";
import OtherChargesBreakUp from "./components/OtherChargesBreakUp";

const rowsPerPage = 50;

const ShipmentCreationJodl = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [shipmentData, setShipmentsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQuickFilter, setSelectedQuickFilter] = useState("All");
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [isMarkAllCheckboxChecked, setIsMarkAllCheckboxChecked] =
    useState(false);

  const theme = useTheme();
  const triggerToaster = useToaster();

  useEffect(() => {
    setIsLoading(true);
    fetchBulkShipments();
  }, [page, filters, selectedQuickFilter]);

  const fetchBulkShipments = async () => {
    try {
      const { data } = await postCall_v2(
        `/joms/api/external/v1/bulk-shipment/fetch-uploaded-shipment`,
        {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          filterDto: {
            bulkShipmentCategory: BULK_SHIPMENT_CATEGORY.jodl,
            invoiceNumber: filters?.invoiceNumber?.trim() || null,
            customerGstin: filters?.customerGSTIN?.trim() || null,
            orderNumber: filters?.orderNumber?.trim() || null,
            soLineItemId: filters?.soLineItem?.trim() || null,
            status: filters?.status || null,
            orderCategory: filters?.orderCategory || null,
            sellerOrderNumber: filters?.sellerOrderNumber?.trim() || null,
            orderNumber: filters?.orderNumber?.trim() || null,
            quickFilter: QUICK_FILTERS?.[selectedQuickFilter],
            deliveryStates: filters?.deliveryStates || null,
            businessUnit: filters?.businessUnit || null,
          },
        },
      );
      if (data) {
        setShipmentsData(data);
      }
    } catch (err) {
      triggerToaster(err?.data?.errorMessage || "Something went wrong!", error);
      console.error("Error fetching shipments:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterSubmit = (filterData) => {
    setFilters(filterData);
  };

  const handleRefresh = async (shipmentIdList) => {
    try {
      const payload = {
        shipmentOrderLineItemAggregateIds: shipmentIdList,
      };
      await postCall_v2(
        "/joms/api/external/v1/bulk/sap/upload/refresh",
        payload,
      );
      setSelectedShipments([]);
      fetchBulkShipments();
      setIsMarkAllCheckboxChecked(false);
      triggerToaster("Successfully refreshed details", success);
    } catch (err) {
      triggerToaster(
        err?.data?.errorMessage || "Please try again later",
        error,
      );
    }
  };

  const handleAllCheckBox = useCallback(
    (event) => {
      const isChecked = event.target.checked;
      setIsMarkAllCheckboxChecked(isChecked);
      if (isChecked) {
        const allShipmentIds =
          shipmentData?.shipmentOrderLineitemAggregateDtoList?.filter((row) => {
            return (
              row?.validationErrorMsg?.length > 0 ||
              !!row?.shipmentCreationErrorMsg
            );
          }) || [];

        const formattedSelectedIds = allShipmentIds?.map((row) => row?.id);
        setSelectedShipments(formattedSelectedIds);
      } else {
        setSelectedShipments([]);
      }
    },
    [shipmentData],
  );

  const handleCheckBox = useCallback(
    (row) => {
      setSelectedShipments((prev) => {
        const isSelected = prev.includes(row?.id);
        const newSelected = isSelected
          ? prev.filter((id) => id !== row?.id)
          : [...prev, row?.id];
        setIsMarkAllCheckboxChecked(
          newSelected.length ===
            shipmentData?.shipmentOrderLineitemAggregateDtoList?.length,
        );
        return newSelected;
      });
    },
    [shipmentData],
  );

  const shipmentApproval = async (itemDetails, action) => {
    try {
      const actionMapper = {
        APPROVE: "APPROVED",
        REJECT: "REJECTED",
      };
      const payload = {
        shipmentLineItemAggregateId: itemDetails.id,
        status: actionMapper[action],
      };

      await postCall_v2(
        "/joms/api/external/v1/bulk/sap/change-status",
        payload,
      );
      triggerToaster(
        `Shipment is successfully ${actionMapper[action].toLowerCase()}`,
        success,
      );
      fetchBulkShipments();
    } catch (err) {
      triggerToaster(
        err?.data?.errorMessage || "Please try again later",
        error,
      );
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case SHIPMENT_STATUS?.approved:
      case SHIPMENT_STATUS?.created:
        return theme.palette.success?.dark;
      case SHIPMENT_STATUS?.rejected:
      case SHIPMENT_STATUS?.failed:
        return theme.palette.error?.errorTextColor;
      default:
        break;
    }
  };

  const handleQuickFilter = (selectedFilter) => {
    setSelectedQuickFilter(selectedFilter);
  };

  const handlePageChange = (_event, pageNumber) => {
    setPage(pageNumber);
  };

  const jodlShipmentList = shipmentData?.shipmentOrderLineitemAggregateDtoList;

  const isRefreshEnabled = selectedShipments.length > 0;

  const handleFilterReset = () => {
    setSelectedQuickFilter("All");
  };

  return (
    <>
      <Box>
        <Filters
          onFilterSubmit={handleFilterSubmit}
          onResetFilter={handleFilterReset}
        />
      </Box>
      <Box
        mt={4}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <QuickFilter
          onQuickFilter={handleQuickFilter}
          state={selectedQuickFilter}
        />
        <ButtonV1
          title="Refresh for all errors"
          disabled={!isRefreshEnabled && !selectedShipments.length}
          onClick={() => handleRefresh(selectedShipments)}
        />
      </Box>
      <Box mt={4}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={handleAllCheckBox}
                    checked={isMarkAllCheckboxChecked}
                  />
                </TableCell>
                {SHIPMENT_CREATION_JODL_HEADERS.map((header) => (
                  <TableCell key={header}>
                    <Typography fontSize={14} fontWeight="bold">
                      {header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell
                    colSpan={SHIPMENT_CREATION_JODL_HEADERS.length + 2}
                  >
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {(jodlShipmentList || []).map((row, index) => {
                const errors = row?.validationErrorMsg || [];

                if (row?.shipmentCreationErrorMsg) {
                  errors.push(row?.shipmentCreationErrorMsg);
                }
                const sapShipmentDetailListData =
                  row?.sapShipmentDetailList?.sapShipmentDetailList;

                const invoiceValue = sapShipmentDetailListData?.reduce(
                  (total, item) => {
                    return (
                      total +
                      +item?.quantity *
                        (+item?.lineItemUnitPriceWithTax?.amount +
                          +item?.lineItemUnitPriceWithTax?.taxAmount)
                    );
                  },
                  0,
                );

                const bgColor =
                  index % 2 === 0
                    ? theme.palette.common?.white
                    : theme.palette?.bgColor;

                let sumOfProcessingCharges = 0;
                if (row?.processingCharges?.length) {
                  sumOfProcessingCharges = row?.processingCharges?.reduce(
                    (total, item) =>
                      total +
                      (item?.amount +
                        item?.amount * item?.taxPercentage * 0.01 || 0),
                    0,
                  );
                }

                const shipmentDetails =
                  shipmentData?.shipmentOrderLineitemAggregateDtoList?.[index];

                return (
                  <>
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor: bgColor,
                      }}
                    >
                      <TableCell>
                        {!!errors?.length && (
                          <Checkbox
                            checked={selectedShipments.includes(row?.id)}
                            onChange={() => handleCheckBox(row)}
                          />
                        )}
                      </TableCell>
                      <TableCell>{validateNull(row?.orderNumber)}</TableCell>
                      <TableCell>
                        {validateNull(row?.sellerOrderNumber)}
                      </TableCell>
                      <TableCell>{validateNull(row?.buyerNameERP)}</TableCell>
                      <TableCell>
                        {validateNull(pascalCase(row?.sapModeOfShipment))}
                      </TableCell>
                      <TableCell>
                        <TooltipLabel
                          placement="bottom"
                          title={
                            row.invoiceNumber && (
                              <TooltipAttachmentView
                                shipmentDetails={shipmentDetails}
                              />
                            )
                          }
                          labelChildren={
                            <Typography
                              sx={{
                                cursor: "pointer",
                                color: theme.palette.primary?.main,
                                fontSize: 14,
                              }}
                            >
                              {validateNull(row.invoiceNumber)}
                            </Typography>
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {moneyFormat(decimalRoundOff(invoiceValue))}
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <TooltipLabel
                          placement="bottom"
                          title={
                            row?.processingCharges?.length && (
                              <OtherChargesBreakUp
                                processingCharges={row?.processingCharges}
                              />
                            )
                          }
                          labelChildren={
                            <Typography
                              sx={{
                                cursor: "pointer",
                                color: theme.palette.primary?.main,
                                fontSize: 14,
                              }}
                            >
                              {moneyFormat(
                                decimalRoundOff(sumOfProcessingCharges),
                              )}
                            </Typography>
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{ display: "flex", flexDirection: "row" }}
                          gap={2}
                        >
                          {!!errors?.length ? (
                            <ButtonV1
                              variant="contained"
                              title="Refresh"
                              size="small"
                              onClick={() => {
                                handleRefresh([row.id]);
                              }}
                            />
                          ) : row?.status === SHIPMENT_STATUS?.pending ? (
                            <>
                              <ButtonV1
                                variant="outlined"
                                title="Reject"
                                color="buttonError"
                                size="small"
                                onClick={() => shipmentApproval(row, "REJECT")}
                              />
                              <ButtonV1
                                title="Approve"
                                size="small"
                                onClick={() => shipmentApproval(row, "APPROVE")}
                              />
                            </>
                          ) : (
                            <Typography
                              fontSize={14}
                              fontWeight={"600"}
                              color={getStatusColor(row?.status)}
                            >
                              {pascalCase(row?.status)}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                    {sapShipmentDetailListData?.length &&
                      sapShipmentDetailListData?.map((item) => {
                        const pendingReleaseQty =
                          item?.totalDoReleasedQuantity -
                          +item?.totalShippedQuantity -
                          +item?.totalDoShortClosedQuantity;

                        const invoicedPrice =
                          item?.lineItemUnitPriceWithTax?.amount;

                        const tooltipData = {
                          soLine: item?.soLineItemNumbers.join(","),
                          pendingReleaseQty: pendingReleaseQty,
                          invoicedPrice,
                        };

                        return (
                          <TableRow
                            style={{
                              backgroundColor: bgColor,
                            }}
                          >
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell
                              style={{
                                borderTop: `1.5px solid ${theme.palette?.grey["400"]}`,
                              }}
                            >
                              <TooltipLabel
                                placement="bottom"
                                title={
                                  item.skuProduct && (
                                    <TooltipView data={tooltipData} />
                                  )
                                }
                                labelChildren={
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                      color: theme.palette.primary?.main,
                                      fontSize: 14,
                                    }}
                                  >
                                    {validateNull(item.skuProduct)}
                                  </Typography>
                                }
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                borderTop: `1.5px solid ${theme.palette?.grey["400"]}`,
                              }}
                            >
                              {`${validateNull(decimalRoundOff(item.quantity))}
                              ${item?.measurementUnit}`}
                            </TableCell>
                            <TableCell />
                            <TableCell />
                          </TableRow>
                        );
                      })}

                    <ErrorView
                      errors={errors}
                      warning={row?.validationWarningMsg ?? ""}
                      colSpan={SHIPMENT_CREATION_JODL_HEADERS.length + 1}
                    />
                  </>
                );
              })}
              {!isLoading && !jodlShipmentList?.length && (
                <TableRow>
                  <TableCell
                    colSpan={SHIPMENT_CREATION_JODL_HEADERS.length + 2}
                  >
                    <Typography textAlign={"center"}>No data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!!shipmentData && (
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              rowsPerPage={rowsPerPage}
              page={Math.ceil(shipmentData?.offset / rowsPerPage) ?? 0}
              count={shipmentData?.totalCount ?? 0}
              onPageChange={handlePageChange}
              sx={{ display: "flex", flexDirection: "column-reverse" }}
              ActionsComponent={PaginationAction}
            />
          )}
        </TableContainer>
      </Box>
    </>
  );
};

export default ShipmentCreationJodl;
