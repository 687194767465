import { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Typography,
  Paper,
  useTheme,
  TablePagination,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { GridView } from "./components";
import ErrorView from "../components/ErrorView";

import { BULK_SHIPMENT } from "./constants";
import { Loader, PaginationAction } from "components";
import { decimalRoundOff, formatDate, moneyFormat, validateNull } from "utils";

const { REVIEW_TABLE_HEADER } = BULK_SHIPMENT;

const ViewOnly = ({ label, value, type = "string" }) => {
  const getFormattedValue = () => {
    let formattedValue = "";
    if (type === "number") {
      formattedValue = decimalRoundOff(value);
    } else if (type === "money") {
      formattedValue = moneyFormat(value);
    } else {
      formattedValue = validateNull(value);
    }
    return formattedValue;
  };

  return (
    <Box>
      <Typography style={{ fontSize: 14, lineHeight: 2 }}>{label}</Typography>
      <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
        {getFormattedValue()}
      </Typography>
    </Box>
  );
};

const Row = ({ row }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const { sapShipmentUploadRequest } = row;
  const errors = row?.validationErrorMsg || [];

  return (
    <>
      {/* Parent Row */}
      <TableRow>
        <TableCell>{validateNull(row.sapBuyerGstin)}</TableCell>
        <TableCell>{validateNull(row.sapCustomerName)}</TableCell>
        <TableCell>{validateNull(row.soNumber)}</TableCell>
        <TableCell>{validateNull(row.soLineItemNumber)}</TableCell>
        <TableCell>{validateNull(row.invoiceNumber)}</TableCell>
        <TableCell>
          {row.createdDate ? formatDate(row.createdDate) : "-"}
        </TableCell>
        <TableCell>{validateNull(row.fileUploadedBy)}</TableCell>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      {/* Collapsible Content */}
      <TableRow>
        <TableCell
          colSpan={REVIEW_TABLE_HEADER.length}
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: theme.palette.common.white,
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              margin={2}
              paddingBottom={4}
              sx={{
                borderBottom: `1px solid ${theme.palette.grey["400"]}`,
              }}
            >
              <Box mb={4}>
                {/* 1 */}
                <GridView
                  style={{ padding: 2 }}
                  firstChild={
                    <ViewOnly
                      label={"Sold to party no"}
                      value={sapShipmentUploadRequest?.["Sold to Party No"]}
                    />
                  }
                  secondChild={
                    <ViewOnly
                      label={"Sold to party name"}
                      value={sapShipmentUploadRequest?.["Sold To Party Name"]}
                    />
                  }
                  thirdChild={
                    <ViewOnly
                      label={"Sold to party city"}
                      value={sapShipmentUploadRequest?.["Sold to Party City"]}
                    />
                  }
                  fourthChild={
                    <ViewOnly
                      label={"Sold to party state"}
                      value={sapShipmentUploadRequest?.["Sold to Party State"]}
                    />
                  }
                  fifthChild={
                    <ViewOnly
                      label={"Billing description"}
                      value={sapShipmentUploadRequest?.["Billing Description"]}
                    />
                  }
                  sixthChild={
                    <ViewOnly
                      label={"Distribution channel"}
                      value={sapShipmentUploadRequest?.["Distribution channel"]}
                    />
                  }
                />
                {/* 2 */}
                <GridView
                  style={{ padding: 2, marginTop: 8 }}
                  firstChild={
                    <ViewOnly
                      label={"ODN no"}
                      value={sapShipmentUploadRequest?.["ODN No"]}
                    />
                  }
                  secondChild={
                    <ViewOnly
                      label={"Internal document no"}
                      value={
                        sapShipmentUploadRequest?.["Internal Document No."]
                      }
                    />
                  }
                  thirdChild={
                    <ViewOnly
                      label={"Inter doc date"}
                      value={sapShipmentUploadRequest?.["Inter Doc Date"]}
                    />
                  }
                  fourthChild={
                    <ViewOnly
                      label={"Ship to Party Name"}
                      value={sapShipmentUploadRequest?.["Ship to Party Name"]}
                    />
                  }
                  fifthChild={
                    <ViewOnly
                      label={"So description"}
                      value={sapShipmentUploadRequest?.["So Description"]}
                    />
                  }
                  sixthChild={
                    <ViewOnly
                      label={"Payment terms"}
                      value={sapShipmentUploadRequest?.["Payment Terms"]}
                    />
                  }
                />
                {/* 3 */}
                <GridView
                  style={{ padding: 2, marginTop: 8 }}
                  firstChild={
                    <ViewOnly
                      label={"Ship to party no"}
                      value={sapShipmentUploadRequest?.["Ship to Party No"]}
                    />
                  }
                  secondChild={
                    <ViewOnly
                      label={"Ship to party city"}
                      value={sapShipmentUploadRequest?.["Ship to Party City"]}
                    />
                  }
                  thirdChild={
                    <ViewOnly
                      label={"Ship to party state"}
                      value={sapShipmentUploadRequest?.["Ship to Party State"]}
                    />
                  }
                  fourthChild={
                    <ViewOnly
                      label={"GSTIN ship to"}
                      value={sapShipmentUploadRequest?.["GSTIN Ship to"]}
                    />
                  }
                  fifthChild={
                    <ViewOnly
                      label={"Payment description"}
                      value={sapShipmentUploadRequest?.["Payment Description"]}
                    />
                  }
                  sixthChild={
                    <ViewOnly
                      label={"Source plant"}
                      value={sapShipmentUploadRequest?.["Source Plant"]}
                    />
                  }
                />
                {/* 4 */}
                <GridView
                  style={{ padding: 2, marginTop: 8 }}
                  firstChild={
                    <ViewOnly
                      label={"Bill to party no"}
                      value={sapShipmentUploadRequest?.["Bill to Party No"]}
                    />
                  }
                  secondChild={
                    <ViewOnly
                      label={"Bill to party name"}
                      value={sapShipmentUploadRequest?.["Bill to Party Name"]}
                    />
                  }
                  thirdChild={
                    <ViewOnly
                      label={"GST number"}
                      value={sapShipmentUploadRequest?.["GST Number"]}
                    />
                  }
                  fourthChild={
                    <ViewOnly
                      label={"Payer no"}
                      value={sapShipmentUploadRequest?.["Payer No"]}
                    />
                  }
                  fifthChild={
                    <ViewOnly
                      label={"Invoice time"}
                      value={sapShipmentUploadRequest?.["Invoice Time"]}
                    />
                  }
                  sixthChild={
                    <ViewOnly
                      label={"Sales office"}
                      value={sapShipmentUploadRequest?.["Sales Office"]}
                    />
                  }
                />
                {/* 5 */}
                <GridView
                  style={{ padding: 2, marginTop: 8 }}
                  firstChild={
                    <ViewOnly
                      label={"Payer party name"}
                      value={sapShipmentUploadRequest?.["Payer Party Name"]}
                    />
                  }
                  secondChild={
                    <ViewOnly
                      label={"Transporter name"}
                      value={sapShipmentUploadRequest?.["Transporter Name"]}
                    />
                  }
                  thirdChild={
                    <ViewOnly
                      label={"Invoice item"}
                      value={sapShipmentUploadRequest?.["Invoice Item"]}
                    />
                  }
                  fourthChild={
                    <ViewOnly
                      label={"Sales order no"}
                      value={sapShipmentUploadRequest?.["Sales Order No"]}
                    />
                  }
                  fifthChild={
                    <ViewOnly
                      label={"Sales district"}
                      value={sapShipmentUploadRequest?.["Sales District"]}
                    />
                  }
                  sixthChild={
                    <ViewOnly
                      label={"Gross Wt"}
                      type="number"
                      value={sapShipmentUploadRequest?.["Gross Wt"]}
                    />
                  }
                />
                {/* 6 */}
                <GridView
                  style={{ padding: 2, marginTop: 8 }}
                  firstChild={
                    <ViewOnly
                      label={"Sales doc item"}
                      value={sapShipmentUploadRequest?.["Sales Doc Item"]}
                    />
                  }
                  secondChild={
                    <ViewOnly
                      label={"Sales ord date"}
                      value={sapShipmentUploadRequest?.["Sales Ord Date"]}
                    />
                  }
                  thirdChild={
                    <ViewOnly
                      label={"Sales doc type"}
                      value={sapShipmentUploadRequest?.["Sales Doc Type"]}
                    />
                  }
                  fourthChild={
                    <ViewOnly
                      label={"PO number"}
                      value={sapShipmentUploadRequest?.["PO NUMBER"]}
                    />
                  }
                  fifthChild={
                    <ViewOnly
                      label={"Net Wt."}
                      type="number"
                      value={sapShipmentUploadRequest?.["Net Wt."]}
                    />
                  }
                  sixthChild={
                    <ViewOnly
                      type="money"
                      label={"Taxable value"}
                      value={sapShipmentUploadRequest?.["Taxable Value"]}
                    />
                  }
                />
                {/* 7 */}
                <GridView
                  style={{ padding: 2, marginTop: 8 }}
                  firstChild={
                    <ViewOnly
                      type="money"
                      label={"Total value"}
                      value={sapShipmentUploadRequest?.["Total Value"]}
                    />
                  }
                  secondChild={
                    <ViewOnly
                      label={"Driver mobile number"}
                      value={sapShipmentUploadRequest?.["Driver Mobile Number"]}
                    />
                  }
                  thirdChild={
                    <ViewOnly
                      label={"JTC1 value"}
                      value={sapShipmentUploadRequest?.["JTC1 Value"]}
                    />
                  }
                  fourthChild={
                    <ViewOnly
                      label={"Status"}
                      value={sapShipmentUploadRequest?.["Status"]}
                    />
                  }
                  fifthChild={
                    <ViewOnly
                      label={"Shipment No."}
                      value={sapShipmentUploadRequest?.["Shipment No."]}
                    />
                  }
                  sixthChild={
                    <ViewOnly
                      label={"Shipment date"}
                      value={sapShipmentUploadRequest?.["Shipment Date"]}
                    />
                  }
                />
                {/* 8 */}
                <GridView
                  style={{ padding: 2, marginTop: 8 }}
                  firstChild={
                    <ViewOnly
                      label={"Shipment type"}
                      value={sapShipmentUploadRequest?.["Shipment Type"]}
                    />
                  }
                  secondChild={
                    <ViewOnly
                      label={"Product"}
                      value={sapShipmentUploadRequest?.["Product"]}
                    />
                  }
                  thirdChild={
                    <ViewOnly
                      label={"Product description"}
                      value={sapShipmentUploadRequest?.["PRODUCT DESCRIPTION"]}
                    />
                  }
                  fourthChild={
                    <ViewOnly
                      label={"Billing type"}
                      value={sapShipmentUploadRequest?.["Billing Type"]}
                    />
                  }
                  fifthChild={
                    <ViewOnly
                      label={"Truck no"}
                      value={sapShipmentUploadRequest?.["Truck No"]}
                    />
                  }
                  sixthChild={
                    <ViewOnly
                      label={"Batch no"}
                      value={sapShipmentUploadRequest?.["Batch no"]}
                    />
                  }
                />
                {/* 9 */}
                <GridView
                  style={{ padding: 2, marginTop: 8 }}
                  firstChild={
                    <ViewOnly
                      label={"Storage location"}
                      value={sapShipmentUploadRequest?.["Storage Location"]}
                    />
                  }
                  secondChild={
                    <ViewOnly
                      label={"Plant GST"}
                      value={sapShipmentUploadRequest?.["Plant GST"]}
                    />
                  }
                  thirdChild={
                    <ViewOnly
                      label={"E-way bill No."}
                      value={sapShipmentUploadRequest?.["E-way bill No."]}
                    />
                  }
                  fourthChild={
                    <ViewOnly
                      label={"CINV LR Num"}
                      value={sapShipmentUploadRequest?.["CINV LR Num"]}
                    />
                  }
                  fifthChild={
                    <ViewOnly
                      label={"Ship rake wagon"}
                      value={sapShipmentUploadRequest?.["Ship Rake Wagon"]}
                    />
                  }
                  sixthChild={
                    <ViewOnly
                      label={"Ship rake ref"}
                      value={sapShipmentUploadRequest?.["Ship Rake Ref"]}
                    />
                  }
                />
                {/* 10 */}
                <GridView
                  style={{ padding: 2, marginTop: 8 }}
                  firstChild={
                    <ViewOnly
                      label={"Delivery number"}
                      value={sapShipmentUploadRequest?.["Delivery Number"]}
                    />
                  }
                  secondChild={
                    <ViewOnly
                      label={"BPSL LR Num"}
                      value={sapShipmentUploadRequest?.["BPSL LR Num"]}
                    />
                  }
                  thirdChild={
                    <ViewOnly
                      label={"Unit price"}
                      type="money"
                      value={sapShipmentUploadRequest?.["Unit Price"]}
                    />
                  }
                  fourthChild={
                    <ViewOnly
                      label={"Delivery type"}
                      value={sapShipmentUploadRequest?.["Delivery Type"]}
                    />
                  }
                  fifthChild={
                    <ViewOnly
                      label={"Mode of shipment"}
                      value={sapShipmentUploadRequest?.["Mode of Shipment"]}
                    />
                  }
                  sixthChild={
                    <ViewOnly
                      label={"Plant code"}
                      value={sapShipmentUploadRequest?.["Plant Code"]}
                    />
                  }
                />
                {/* 11 */}
                <GridView
                  style={{ padding: 2, marginTop: 8 }}
                  firstChild={
                    <ViewOnly
                      label={"Material Desc"}
                      value={sapShipmentUploadRequest?.["MATERIAL DESC"]}
                    />
                  }
                />
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ErrorView errors={errors} />
    </>
  );
};

const ReviewContent = ({
  bulkShipmentData,
  onPageChange,
  rowsPerPage,
  isLoading,
}) => {
  const bulkList = bulkShipmentData?.rawBulkShipmentUploadDTOS;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {REVIEW_TABLE_HEADER.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {((!isLoading && bulkList) || []).map((row, index) => (
            <Row key={index} row={row} />
          ))}
          {!isLoading && !bulkList?.length && (
            <TableRow>
              <TableCell colSpan={REVIEW_TABLE_HEADER.length + 1}>
                <Typography textAlign={"center"}>No data found</Typography>
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={REVIEW_TABLE_HEADER.length + 1}>
                <Loader />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {!!bulkList?.length && (
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          rowsPerPage={rowsPerPage}
          page={Math.ceil(bulkShipmentData?.offset / rowsPerPage)}
          count={bulkShipmentData?.totalCount}
          onPageChange={onPageChange}
          sx={{ display: "flex", flexDirection: "column-reverse" }}
          ActionsComponent={PaginationAction}
        />
      )}
    </TableContainer>
  );
};

export default ReviewContent;
