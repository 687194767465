import { useCallback, useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  TablePagination,
  Checkbox,
} from "@mui/material";

import { ButtonV1, Loader, PaginationAction, TooltipLabel } from "components";
import { Filters, QuickFilter, TooltipView } from "./components";
import ErrorView from "../components/ErrorView";

import { useToaster } from "hooks";

import { decimalRoundOff, moneyFormat, pascalCase, validateNull } from "utils";
import { postCall_v2 } from "services";

import { error, success } from "constants";
import {
  QUICK_FILTERS,
  SHIPMENT_CREATION_PLANT_SUPPLY_HEADERS,
  BULK_SHIPMENT_CATEGORY,
  SHIPMENT_STATUS,
} from "../constant";

const rowsPerPage = 50;

const ShipmentCreationPlantSupply = () => {
  const [shipmentData, setShipmentsData] = useState({});
  const [filters, setFilters] = useState({});
  const [selectedQuickFilter, setSelectedQuickFilter] = useState("All");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [isMarkAllCheckboxChecked, setIsMarkAllCheckboxChecked] =
    useState(false);

  const triggerToaster = useToaster();
  const theme = useTheme();

  useEffect(() => {
    setIsLoading(true);
    fetchBulkShipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters, selectedQuickFilter]);

  const fetchBulkShipments = async () => {
    try {
      const { data } = await postCall_v2(
        `/joms/api/external/v1/bulk-shipment/fetch-uploaded-shipment`,
        {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          filterDto: {
            bulkShipmentCategory: BULK_SHIPMENT_CATEGORY.plantSupply,
            invoiceNumber: filters?.invoiceNumber || null,
            customerGstin: filters?.customerGSTIN || null,
            orderNumber: filters?.orderNumber || null,
            plantCode: filters?.plantCode || null,
            soNumber: filters?.soNumber || null,
            soLineItemId: filters?.soLineItem || null,
            sapShipmentNumber: filters?.shipmentNumber || null,
            status: filters?.status || null,
            quickFilter: QUICK_FILTERS?.[selectedQuickFilter],
          },
        },
      );

      if (data) {
        setShipmentsData(data);
      }
    } catch (err) {
      triggerToaster(err?.data?.errorMessage || "Something went wrong!", error);
      console.error("Error fetching shipments:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  const handleFilterSubmit = (filterData) => {
    setFilters(filterData);
  };

  const handleQuickFilter = (selectedFilter) => {
    setSelectedQuickFilter(selectedFilter);
  };

  const handleAllCheckBox = useCallback(
    (event) => {
      const isChecked = event.target.checked;
      setIsMarkAllCheckboxChecked(isChecked);
      if (isChecked) {
        const allShipmentIds =
          shipmentData?.shipmentOrderLineitemAggregateDtoList?.map(
            (row) => row?.id,
          ) || [];
        setSelectedShipments(allShipmentIds);
      } else {
        setSelectedShipments([]);
      }
    },
    [shipmentData],
  );

  const handleCheckBox = useCallback(
    (row) => {
      setSelectedShipments((prev) => {
        const isSelected = prev.includes(row?.id);
        const newSelected = isSelected
          ? prev.filter((id) => id !== row?.id)
          : [...prev, row?.id];
        setIsMarkAllCheckboxChecked(
          newSelected.length ===
            shipmentData?.shipmentOrderLineitemAggregateDtoList?.length,
        );
        return newSelected;
      });
    },
    [shipmentData],
  );

  const handleRefresh = async (shipmentIdList) => {
    try {
      const payload = {
        shipmentOrderLineItemAggregateIds: shipmentIdList,
      };
      await postCall_v2(
        "/joms/api/external/v1/bulk/sap/upload/refresh",
        payload,
      );
      setSelectedShipments([]);
      fetchBulkShipments();
      setIsMarkAllCheckboxChecked(false);
      triggerToaster("Successfully refreshed details", success);
    } catch (err) {
      triggerToaster(
        err?.data?.errorMessage || "Please try again later",
        error,
      );
    }
  };

  const shipmentApproval = async (itemDetails, action) => {
    try {
      const actionMapper = {
        APPROVE: "APPROVED",
        REJECT: "REJECTED",
      };
      const payload = {
        shipmentLineItemAggregateId: itemDetails.id,
        status: actionMapper[action],
      };
      await postCall_v2(
        "/joms/api/external/v1/bulk/sap/change-status",
        payload,
      );
      triggerToaster(
        `Shipment is successfully ${actionMapper[action].toLowerCase()}`,
        success,
      );
      fetchBulkShipments();
    } catch (err) {
      triggerToaster(
        err?.data?.errorMessage || "Please try again later",
        error,
      );
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case SHIPMENT_STATUS?.approved:
      case SHIPMENT_STATUS?.created:
        return theme.palette.success.dark;
      case SHIPMENT_STATUS?.rejected:
      case SHIPMENT_STATUS?.failed:
        return theme.palette.error.errorTextColor;
      default:
        break;
    }
  };

  const plantSupplyShipmentList =
    shipmentData?.shipmentOrderLineitemAggregateDtoList;

  const isRefreshEnabled = selectedShipments.length > 0;

  return (
    <Box>
      <Box>
        <Filters onFilterSubmit={handleFilterSubmit} />
      </Box>
      <Box
        mt={4}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <QuickFilter
          onQuickFilter={handleQuickFilter}
          state={selectedQuickFilter}
        />
        <ButtonV1
          title="Refresh for all errors"
          disabled={!isRefreshEnabled && !selectedShipments.length}
          onClick={() => handleRefresh(selectedShipments)}
        />
      </Box>
      <Box mt={4}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={handleAllCheckBox}
                    checked={isMarkAllCheckboxChecked}
                  />
                </TableCell>
                {SHIPMENT_CREATION_PLANT_SUPPLY_HEADERS.map((header) => (
                  <TableCell key={header}>
                    <Typography fontSize={14} fontWeight="bold">
                      {header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell
                    colSpan={SHIPMENT_CREATION_PLANT_SUPPLY_HEADERS.length + 2}
                  >
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {(plantSupplyShipmentList || []).map((row, index) => {
                const errors = row?.validationErrorMsg || [];

                if (row?.shipmentCreationErrorMsg) {
                  errors.push(row?.shipmentCreationErrorMsg);
                }
                const sapShipmentDetailListData =
                  row?.sapShipmentDetailList?.sapShipmentDetailList;

                const invoiceValue = sapShipmentDetailListData?.reduce(
                  (total, item) => {
                    return (
                      total +
                      +item?.quantity *
                        (+item?.lineItemUnitPriceWithTax?.amount +
                          +item?.lineItemUnitPriceWithTax?.taxAmount)
                    );
                  },
                  0,
                );

                return (
                  <>
                    <TableRow key={index}>
                      <TableCell>
                        {!!errors?.length && (
                          <Checkbox
                            checked={selectedShipments.includes(row?.id)}
                            onChange={() => handleCheckBox(row)}
                          />
                        )}
                      </TableCell>
                      <TableCell>{validateNull(row?.orderNumber)}</TableCell>
                      <TableCell>{validateNull(row?.buyerNameERP)}</TableCell>
                      <TableCell>
                        {validateNull(row?.sapCustomerName)}
                      </TableCell>
                      <TableCell>{validateNull(row?.invoiceNumber)}</TableCell>
                      <TableCell>
                        {moneyFormat(decimalRoundOff(invoiceValue))}
                      </TableCell>
                      <TableCell />

                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <Box
                          sx={{ display: "flex", flexDirection: "row" }}
                          gap={2}
                        >
                          {!!errors?.length ? (
                            <ButtonV1
                              variant="contained"
                              title="Refresh"
                              size="small"
                              onClick={() => {
                                handleRefresh([row.id]);
                              }}
                            />
                          ) : row?.status === SHIPMENT_STATUS?.pending ? (
                            <>
                              <ButtonV1
                                variant="outlined"
                                title="Reject"
                                color="buttonError"
                                size="small"
                                onClick={() => shipmentApproval(row, "REJECT")}
                              />
                              <ButtonV1
                                title="Approve"
                                size="small"
                                onClick={() => shipmentApproval(row, "APPROVE")}
                              />
                            </>
                          ) : (
                            <Typography
                              fontSize={14}
                              fontWeight={"600"}
                              color={getStatusColor(row?.status)}
                            >
                              {pascalCase(row?.status)}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                    {sapShipmentDetailListData?.length &&
                      sapShipmentDetailListData?.map((item) => {
                        const pendingReleaseQty =
                          item?.totalDoReleasedQuantity -
                          +item?.totalShippedQuantity -
                          +item?.totalDoShortClosedQuantity;

                        const invoicedPrice =
                          item?.lineItemUnitPriceWithTax?.amount;

                        const tooltipData = {
                          soLine: item?.soLineItemNumbers.join(","),
                          pendingReleaseQty: pendingReleaseQty,
                          invoicedPrice,
                        };

                        return (
                          <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell>
                              <TooltipLabel
                                customStyle={{ width: 400 }}
                                placement="bottom"
                                title={
                                  item.skuProduct && (
                                    <TooltipView data={tooltipData} />
                                  )
                                }
                                labelChildren={
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                      color: theme.palette.primary.main,
                                      fontSize: 14,
                                    }}
                                  >
                                    {validateNull(item.skuProduct)}
                                  </Typography>
                                }
                              />
                            </TableCell>
                            <TableCell>
                              {validateNull(item.sapSkuProduct)}
                            </TableCell>
                            <TableCell>
                              {`${validateNull(decimalRoundOff(item.quantity))}
                              ${item?.measurementUnit}`}
                            </TableCell>
                            <TableCell />
                          </TableRow>
                        );
                      })}

                    <ErrorView
                      errors={errors}
                      warning={row?.validationWarningMsg ?? ""}
                    />

                    {!isLoading && !plantSupplyShipmentList?.length && (
                      <TableRow>
                        <TableCell
                          colSpan={
                            SHIPMENT_CREATION_PLANT_SUPPLY_HEADERS.length + 2
                          }
                        >
                          <Typography textAlign={"center"}>
                            No data found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                );
              })}
            </TableBody>
          </Table>
          {!!shipmentData && (
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              rowsPerPage={rowsPerPage}
              page={Math.ceil(shipmentData?.offset / rowsPerPage) ?? 0}
              count={shipmentData?.totalCount ?? 0}
              onPageChange={handlePageChange}
              sx={{ display: "flex", flexDirection: "column-reverse" }}
              ActionsComponent={PaginationAction}
            />
          )}
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ShipmentCreationPlantSupply;
