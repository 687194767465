import { Grid, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

import { decimalRoundOff, moneyFormat, pascalCase } from "utils";

const OtherChargesBreakUp = ({ processingCharges }) => {
  const theme = useTheme();
  return (
    <Grid container sx={{ padding: 2, width: 240 }}>
      {!!processingCharges?.length &&
        processingCharges
          ?.filter((charge) => charge?.amount > 0)
          ?.map((item, i) => {
            const amountWithTax =
              item?.amount + item?.amount * item?.taxPercentage * 0.01;
            return (
              <>
                <Grid
                  item
                  xs={8}
                  sx={{
                    borderTop:
                      i > 0 && `1px solid ${theme.palette.grey["400"]}`,
                    padding: 2,
                  }}
                >
                  <Typography>{pascalCase(item?.description)}</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    borderTop:
                      i > 0 && `1px solid ${theme.palette.grey["400"]}`,
                    padding: 2,
                  }}
                >
                  <Typography fontWeight={"bold"}>
                    {moneyFormat(decimalRoundOff(amountWithTax))}
                  </Typography>
                </Grid>
              </>
            );
          })}
    </Grid>
  );
};

export default OtherChargesBreakUp;

OtherChargesBreakUp.propTypes = {
  processingCharges: PropTypes.array,
};
