import { useEffect, useState, useRef } from "react";
import {
  TableRow,
  TableCell,
  TextField,
  Checkbox,
  Box,
  Grid,
  Divider,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";

import {
  ButtonV1,
  CollapsibleInfo,
  DropdownV1,
  InputField,
  Loader,
  ModalBox,
  TableWrapper,
} from "components";
import { ColumnTitle } from "CustomStyledComponents";
import { LabelContainer } from "./components/LabelContainer";
import ConfirmPaymentModal from "./components/ConfirmPaymentModal";
import DispatchOrderRecord from "./DispatchOrderRecord";
import { useFetchData, useToaster } from "hooks";

import { getAttributes, getFixedValue, moneyFormat } from "utils";
import { postCall_v2 } from "services";
import { paymentRequestPayload, getLineItemData } from "./makeData";
import { error, success } from "constants";
import { DISPATCH_ORDER } from "./constants";

const { dispatchTableHeader, orderStatusKey, orderPaymentTypeKey } =
  DISPATCH_ORDER;
const { checkbox, plannedQty } = DISPATCH_ORDER.yupConfig;

/**
 *
 * @description List all lineitems in tabular form
 */
const DispatchOrderTable = () => {
  const [totalPlanningQuantity, setTotalPlanningQuantity] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [doModalOpen, setDoModalOpen] = useState(false);
  const [lineItem, setLineItem] = useState([]);
  const [pendingValue, setPendingValue] = useState();
  const [advancePercentData, setAdvancePercentData] = useState({});
  const [freightValue, setFreightValue] = useState(0);
  const recordTableRef = useRef(null);

  const triggerToaster = useToaster();
  const theme = useTheme();
  const methods = useForm({
    mode: "onTouch",
  });

  const { control, watch, getValues, setValue } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "dispatchOrderList",
  });

  const {
    buyerDetails,
    orderNumber,
    id: orderId,
    category,
    orderStatus,
    orderPaymentType,
    orderAmount,
    customerFreezed,
    isCreditBlocked,
  } = useSelector((state) => state.orderDetails);
  const gstIn = buyerDetails?.gstin;
  const customerPan = buyerDetails?.gstin.substr(2, 10);

  /**
   *
   * @returns selected or checked lineitem
   */
  const getSelectedData = () => {
    const formValues = getValues();
    const result = formValues?.dispatchOrderList?.filter((ele) => {
      return ele.checkbox;
    });
    return result;
  };

  /**
   *
   * @param {*} value
   * @param {*} lineItem
   * @param {*} onChange
   * @param {*} lineItemId
   * @description update total price depending on user input & check validation
   */
  const handlePlanningQty = (value, lineItem, key) => {
    if (getValues(`dispatchOrderList.${key}.${checkbox}`)) {
      setValue(`dispatchOrderList.${key}.${checkbox}`, false);
      //update should be called after setting checkbox false
      //since update only selects elements which are selected
      updateOrderPayments();
    }
    const checkRemainingQty =
      +lineItem.totalQuantity -
        (+lineItem.releasedQuantity +
          +lineItem.plannedQuantity +
          +lineItem?.totalShortClosedQuantity) >=
      +value;

    if (checkRemainingQty) {
      // const number = value.match(/.\d{3}/g) ? Number(value).toFixed(3) : value;
      // const number = value;
      setValue(`dispatchOrderList.${key}.${plannedQty}`, value);
    } else {
      triggerToaster(
        "Planning quantity should be less than already planned quantity.",
        error,
      );
      const updatedVal = value.replace(/.$/, "");
      setValue(`dispatchOrderList.${key}.${plannedQty}`, updatedVal);
    }
  };

  /** @description toggle modal open close behaviour  */
  const handleToggleConfirmModal = () => setDoModalOpen((prev) => !prev);

  const handleRequestPaymentModal = () => {
    setConfirmModalOpen((prev) => !prev);
    setValue("amount", pendingValue);
  };

  /**
   *
   * @description Highlight advance & pending amount in color
   */
  const getColorCode = (type, value) => {
    let greenColor = theme.palette.success.dark;
    let colorCode;
    switch (type) {
      case "DISPATCH_VALUE_ADVANCE":
        if (value < advancePercentData?.availableAdvanceBalance) {
          colorCode = greenColor;
        }
        break;
      case "DISPATCH_VALUE_CREDIT":
        if (value < advancePercentData?.availableCreditBlocked) {
          colorCode = greenColor;
        }
        break;
      default:
        break;
    }
    return colorCode;
  };

  /**
   * @description On request payment submission
   */
  const onSendRequest = async () => {
    try {
      const requestPaymentBody = {
        orderNumber: orderNumber,
        paymentType: "DO",
        amount: getValues("amount"),
        orderType: category,
      };

      const requestPaymentRes = await postCall_v2(
        `/oms/payment/requestPayment`,
        requestPaymentBody,
      );
      if (requestPaymentRes) {
        triggerToaster(
          "Payment requested, transaction will be updated soon",
          success,
        );
        remove();
        await handleMasterApi();
        handleResetState();
        handleRequestPaymentModal();
      }
    } catch (ex) {
      triggerToaster("Payment creation failed. Pls try again", error);
    }
  };

  // Reset values
  const handleResetState = () => {
    setPendingValue(0);
    setFreightValue(0);
    setTotalValue(0);
    setTotalPlanningQuantity(0);
  };

  /**
   * @description invoked on submit of create DO
   */
  const handleCreateDo = async () => {
    try {
      const payload = paymentRequestPayload(
        lineItem,
        orderId,
        totalValue,
        ledgerBalanceRes?.data?.balance,
        getValues(),
        freightValue,
      );

      await postCall_v2("/oms/plant-supply", payload);
      triggerToaster("Shipment successfully planned", success);
      handleResetState();
      handleToggleConfirmModal();
      remove();
      await handleMasterApi();
      //invoke record api
      recordTableRef.current.invokeRecordApi();
    } catch (ex) {
      triggerToaster("Unable to create D.O.", error);
      console.error(`Error in requesting payment, ${ex}`);
    }
  };

  /**
   * @description update total planning qty and total value based on checkbox
   */

  const updateOrderPayments = () => {
    try {
      let formValues = getValues();
      let totalDO_OrderValue = 0;
      let totalQuantity = 0;
      let uom = "";
      formValues?.dispatchOrderList?.forEach((ele) => {
        if (ele?.checkbox) {
          totalDO_OrderValue =
            totalDO_OrderValue + ele.ratePerUnit * ele.plannedQty;
          totalQuantity = totalQuantity + Number(ele.plannedQty);
          uom = ele.uom;
        }
      });
      const calculateFreightValue = getFreightValue();

      const totalValue = totalDO_OrderValue + +calculateFreightValue;
      //pending value calculation
      let pendingValue;
      const {
        advancePercentage = 0,
        availableAdvanceBalance = 0,
        mandatoryAdvanceBalance = 0,
        availableCreditBlocked = 0,
      } = advancePercentData;
      if (orderPaymentType === orderPaymentTypeKey.cashNcarryAdvance) {
        const totalOrderAmount = orderAmount?.centAmount / 100;

        const advPercentage = +(advancePercentage / 100).toFixed(2);
        const mandatoryAdvanceBalanceProrata =
          totalOrderAmount * advPercentage - mandatoryAdvanceBalance;
        pendingValue =
          totalValue -
          advPercentage * totalValue -
          (availableAdvanceBalance - mandatoryAdvanceBalanceProrata);
      } else {
        pendingValue =
          totalValue - (availableCreditBlocked + availableAdvanceBalance);
      }

      //setting pending value
      pendingValue > 0
        ? setPendingValue(pendingValue.toFixed(2))
        : setPendingValue(0);

      setTotalValue(totalValue);
      setTotalPlanningQuantity(`${totalQuantity} ${uom}`);
      setFreightValue(calculateFreightValue);
    } catch (ex) {
      triggerToaster("Something went wrong. Try after sometime.", error);
      console.error(`Error in updating value, ${ex}`);
    }
  };

  //Invoke on first load below apis
  const { refetch: fetchLedgerBalance, data: ledgerBalanceRes } = useFetchData(
    "ledger-balance",
    `/oms/payment/balance/${gstIn}`,
  );

  const { refetch: fetchAdvancePercent } = useFetchData(
    "advance-percentage",
    `/oms/plant-supply/do/advancePercentage?ordNumber=${orderNumber}`,
    (res) => {
      setAdvancePercentData(res?.data);
    },
  );

  const { refetch: fetchCustomerCreditInfo, data: customerCreditInfo } =
    useFetchData(
      "customer-credit-info",
      `/joms/api/external/v2/getCreditInfoByPan?pan=${customerPan}&gstin=${gstIn}`,
    );

  const { refetch: fetchLineItem, isFetching: isLineItemFetching } =
    useFetchData(
      "lineItem-dispatch",
      `/oms/plant-supply/line-items?orderNumber=${orderNumber}`,
      (res) => {
        //format line item data
        remove();
        const lineItemsModel = res.data?.map((item) => getLineItemData(item));
        lineItemsModel.forEach((element) => append(element));
        setLineItem(lineItemsModel);
      },
    );

  /**
   * @description invoke all required apis at once
   */
  const handleMasterApi = async () => {
    await Promise.allSettled([
      fetchLedgerBalance(),
      fetchAdvancePercent(),
      fetchLineItem(),
    ]);
  };

  useEffect(() => {
    (async () => {
      if (!gstIn || !orderNumber) {
        triggerToaster(`Missing details, please check later`, error);
        return;
      }
      await handleMasterApi();
    })();
    fetchCustomerCreditInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { totalFreightValue } = advancePercentData;
  const dispatchOrderList = getValues("dispatchOrderList");
  /**
   *@description Calculate freight value
   * @returns Calculated freight value
   */
  const getFreightValue = () => {
    const sumOfOrderedQtyPrice = fields?.reduce((total, item) => {
      return total + +item?.totalQuantity * +item?.ratePerUnit;
    }, 0);

    const sumOfPlannedQtyPrice = dispatchOrderList?.reduce((total, item) => {
      return item?.checkbox
        ? total + (+item?.plannedQty || 0) * +item?.ratePerUnit
        : total;
    }, 0);

    const sumOfTotalFreightValue =
      ((totalFreightValue || 0) / +sumOfOrderedQtyPrice) *
      +sumOfPlannedQtyPrice;

    return isNaN(sumOfTotalFreightValue)
      ? 0
      : sumOfTotalFreightValue.toFixed(4);
  };

  // Calculate Advance block pro rata
  const getAdvanceBlockProRata = () => {
    if (orderPaymentType === orderPaymentTypeKey.credit) {
      return 0;
    }
    return moneyFormat(
      (totalValue * Number(advancePercentData?.advancePercentage)) / 100,
    );
  };

  const totalMaterialValue = totalValue - freightValue;

  const isPaymentCreditAndPendingValue =
    orderPaymentType === orderPaymentTypeKey.credit && Number(pendingValue) > 0;

  return (
    <>
      <Box mb={8}>
        <FormProvider {...methods}>
          <TableWrapper
            tableHeader={dispatchTableHeader.map((heading, index) => {
              return (
                <TableCell key={index}>
                  <ColumnTitle>{heading}</ColumnTitle>
                </TableCell>
              );
            })}
            tableBody={
              isLineItemFetching ? (
                <>
                  <TableRow>
                    <TableCell colSpan={dispatchTableHeader.length}>
                      <Loader sx={{ margin: 0 }} />
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  {fields?.length ? (
                    <>
                      {fields?.map((lineItem, index) => {
                        const lineItemId = index;
                        const {
                          totalShortClosedQuantity,
                          uom,
                          releasedQuantity,
                          ratePerUnit,
                          plannedQuantity,
                          totalQuantity,
                        } = lineItem;
                        const isInValidForDO =
                          totalQuantity <=
                          releasedQuantity + totalShortClosedQuantity;
                        return (
                          <TableRow
                            key={lineItemId}
                            sx={{
                              border: 2,
                              borderColor: theme.palette.grey[100],
                            }}
                          >
                            <TableCell>
                              <Controller
                                key={lineItemId}
                                control={control}
                                name={`dispatchOrderList.${lineItemId}.${checkbox}`}
                                render={({ field: { value, onChange } }) => {
                                  const isDisabled = !getValues(
                                    `dispatchOrderList.${lineItemId}.${plannedQty}`,
                                  );
                                  return (
                                    <Checkbox
                                      disabled={isDisabled || isInValidForDO}
                                      checked={isDisabled ? false : value}
                                      onChange={(e) => {
                                        onChange(e);
                                        updateOrderPayments();
                                      }}
                                    />
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell width={260}>
                              {lineItem.skuName}
                              {getAttributes(lineItem?.attributes)}
                            </TableCell>
                            <TableCell>
                              {lineItem.totalQuantity} {lineItem.uom}
                            </TableCell>
                            <TableCell width={180}>
                              <Controller
                                control={control}
                                name={`dispatchOrderList.${lineItemId}.${plannedQty}`}
                                render={({ field: { value } }) => (
                                  <TextField
                                    placeholder="Enter quantity"
                                    size="small"
                                    value={value}
                                    disabled={isInValidForDO}
                                    onChange={({ target: { value } }) =>
                                      handlePlanningQty(
                                        value,
                                        lineItem,
                                        lineItemId,
                                      )
                                    }
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>{`${plannedQuantity} ${uom}`}</TableCell>
                            <TableCell>
                              {`${releasedQuantity} ${uom}`}
                            </TableCell>
                            <TableCell>
                              {`${totalShortClosedQuantity} ${uom}`}
                            </TableCell>
                            <TableCell>{moneyFormat(ratePerUnit)}</TableCell>
                            <TableCell width={150}>
                              {moneyFormat(
                                ratePerUnit *
                                  watch(
                                    `dispatchOrderList.${lineItemId}.${plannedQty}`,
                                  ),
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={dispatchTableHeader.length}
                        sx={{ textAlign: "center" }}
                      >
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )
            }
          />
          {!!totalValue && (
            <>
              <Box
                sx={{
                  backgroundColor: theme.palette.grey["A100"],
                  paddingTop: 1,
                }}
              >
                <Box m={4}>
                  <LabelContainer
                    label={"Total planning quantity:"}
                    value={totalPlanningQuantity}
                  />
                </Box>
                <Divider />
                <CollapsibleInfo
                  headerStyle={{ height: 60 }}
                  style={{ boxShadow: "none" }}
                  summaryStyles={{
                    alignItems: "center",
                    paddingLeft: 10,
                    boxShadow: "none",
                  }}
                  summary={
                    <LabelContainer
                      label={"Dispatch order value:"}
                      value={moneyFormat(totalValue)}
                    />
                  }
                  details={
                    <Box display={"flex"} margin={3}>
                      <Grid container>
                        <LabelContainer
                          label={"Item value:"}
                          value={moneyFormat(totalMaterialValue)}
                        />
                        <LabelContainer
                          label={"Freight value:"}
                          value={moneyFormat(freightValue || 0)}
                        />
                      </Grid>
                    </Box>
                  }
                />
                {!!+pendingValue && (
                  <>
                    <Divider />
                    <Box
                      sx={{
                        margin: 3,
                        marginLeft: 5,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <LabelContainer
                        label={"Pending value (Advance):"}
                        value={moneyFormat(pendingValue)}
                        color={+pendingValue && theme.palette.error.dark}
                      />
                      <ButtonV1
                        title={"Request payment"}
                        onClick={handleRequestPaymentModal}
                        disabled={
                          customerFreezed ||
                          isCreditBlocked ||
                          advancePercentData?.creditBlocked ||
                          isPaymentCreditAndPendingValue
                        }
                      />
                    </Box>
                  </>
                )}
                <Divider />
                <CollapsibleInfo
                  summaryStyles={{
                    alignItems: "center",
                    paddingLeft: 10,
                  }}
                  headerStyle={{ height: 60 }}
                  style={{ boxShadow: "none" }}
                  summary={<Typography>Payment breakdown</Typography>}
                  details={
                    <>
                      <Grid container margin={3}>
                        <LabelContainer
                          label={"Advance required:"}
                          value={moneyFormat(
                            totalValue *
                              (advancePercentData?.orderAdvancePercentage /
                                100),
                          )}
                          color={getColorCode(
                            "DISPATCH_VALUE_ADVANCE",
                            totalValue *
                              (advancePercentData?.orderAdvancePercentage /
                                100),
                          )}
                        />
                        <LabelContainer
                          label={"Credit required:"}
                          value={moneyFormat(
                            totalValue *
                              ((100 -
                                Number(
                                  advancePercentData?.orderAdvancePercentage,
                                )) /
                                100),
                          )}
                          color={getColorCode(
                            "DISPATCH_VALUE_CREDIT",
                            totalValue *
                              ((100 -
                                Number(
                                  advancePercentData?.orderAdvancePercentage,
                                )) /
                                100),
                          )}
                        />
                        <LabelContainer
                          label={"Advance block(Pro rata):"}
                          value={getAdvanceBlockProRata()}
                        />
                      </Grid>
                      <Grid container margin={3}>
                        <LabelContainer
                          label={"Advance available:"}
                          value={moneyFormat(
                            advancePercentData?.availableAdvanceBalance,
                          )}
                        />
                        <LabelContainer
                          label={"Credit blocked:"}
                          value={moneyFormat(
                            advancePercentData?.availableCreditBlocked,
                          )}
                        />
                      </Grid>
                    </>
                  }
                />
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  backgroundColor: theme.palette.grey["A100"],
                  padding: 2,
                }}
              >
                {!+pendingValue && (
                  <ButtonV1
                    title={"Create D.O."}
                    onClick={handleToggleConfirmModal}
                    disabled={
                      [
                        orderStatusKey.cancelled,
                        orderStatusKey.delivered,
                      ].includes(orderStatus) ||
                      customerFreezed ||
                      isCreditBlocked ||
                      advancePercentData?.creditBlocked ||
                      isPaymentCreditAndPendingValue
                    }
                  />
                )}
              </Box>
            </>
          )}
          <ModalBox onCloseModal={handleToggleConfirmModal} open={doModalOpen}>
            <ConfirmPaymentModal
              selectedLineItemList={getSelectedData()}
              onCloseModal={handleToggleConfirmModal}
              onRequestPayment={handleCreateDo}
              estimatedPrice={totalValue}
            />
          </ModalBox>
          <ModalBox
            open={confirmModalOpen}
            onCloseModal={handleRequestPaymentModal}
          >
            <Box p={4}>
              <Typography variant="h4" mb={5} style={{ textAlign: "center" }}>
                Send payment request
              </Typography>
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  mb={4}
                  width={"62%"}
                >
                  <Typography fontWeight={600}>Payment type</Typography>
                  <DropdownV1 disabled={true} value={"DO"} />
                </Box>
                <Box mb={8}>
                  <Controller
                    control={control}
                    name="amount"
                    render={({ field: { value, onChange } }) => (
                      <InputField
                        label="Amount"
                        type="number"
                        value={getFixedValue(value)}
                        onChange={onChange}
                      />
                    )}
                  />
                </Box>
                <ButtonV1
                  title="Send request"
                  onClick={onSendRequest}
                  variant="contained"
                  disabled={[
                    orderStatusKey.cancelled,
                    orderStatusKey.delivered,
                  ].includes(orderStatus)}
                />
              </Box>
            </Box>
          </ModalBox>
        </FormProvider>
      </Box>
      <>
        <Typography variant="h4">Dispatch order record</Typography>
        <DispatchOrderRecord
          ref={recordTableRef}
          isCreditBlocked={
            customerCreditInfo?.data?.isCustomerCreditLimitBlocked
          }
          fetchLineItem={handleMasterApi}
        />
      </>
    </>
  );
};

export default DispatchOrderTable;
