import { useEffect, useState } from "react";
import { Box, Divider, Typography, useTheme } from "@mui/material";

import { ButtonV1, Loader, StatusChip } from "components";
import ShipmentDetails from "./ShipmentDetails";
import ShipmentLineItem from "./ShipmentLineItem";
import ShipmentInvoiceDetails from "./ShipmentInvoiceDetails";
import { Filters, CustomPagination } from "./components";

import { postCall_v2 } from "services";
import { useToaster } from "hooks";
import { pascalCase } from "utils";

import { success, error, STATUS_CHIP } from "constants";
import { BULK_SHIPMENT_CATEGORY, SHIPMENT_STATUS } from "../constant";

const rowsPerPage = 1;

const ShipmentCreationJodl = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [shipmentData, setShipmentsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const triggerToaster = useToaster();

  useEffect(() => {
    setIsLoading(true);
    fetchBulkShipments();
  }, [page, filters]);

  const fetchBulkShipments = async () => {
    try {
      const { data } = await postCall_v2(
        `/joms/api/external/v1/bulk-shipment/fetch-uploaded-shipment`,
        {
          offset: (page - 1) * rowsPerPage,
          limit: rowsPerPage,
          filterDto: {
            bulkShipmentCategory: BULK_SHIPMENT_CATEGORY.jodl,
            invoiceNumber: filters?.invoiceNumber || null,
            customerGstin: filters?.customerGSTIN || null,
            orderNumber: filters?.orderNumber || null,
            soLineItemId: filters?.soLineItem || null,
            status: filters?.status || null,
            orderCategory: filters?.orderCategory || null,
            sellerOrderNumber: filters?.sellerOrderNumber || null,
          },
        },
      );

      if (data) {
        setShipmentsData(data);
      }
    } catch (err) {
      triggerToaster(err?.data?.errorMessage || "Something went wrong!", error);
      console.error("Error fetching shipments:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterSubmit = (filterData) => {
    setFilters(filterData);
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < shipmentData?.totalCount) {
      setPage(page + 1);
    }
  };

  const shipmentDetails =
    shipmentData?.shipmentOrderLineitemAggregateDtoList?.[0];

  const handleShipment = async (action) => {
    try {
      const actionMapper = {
        APPROVE: "APPROVED",
        REJECT: "REJECTED",
      };
      const payload = {
        shipmentLineItemAggregateId: shipmentDetails.id,
        status: actionMapper[action],
      };
      await postCall_v2(
        "/joms/api/external/v1/bulk/sap/change-status",
        payload,
      );
      triggerToaster(
        `Shipment is successfully ${actionMapper[action].toLowerCase()}`,
        success,
      );
    } catch (err) {
      triggerToaster(
        err?.data?.errorMessage || "Please try again later",
        error,
      );
    }
  };

  const getStatusType = (status) => {
    switch (status) {
      case SHIPMENT_STATUS?.approved:
      case SHIPMENT_STATUS?.created:
        return STATUS_CHIP.success;
      case SHIPMENT_STATUS?.rejected:
      case SHIPMENT_STATUS?.failed:
        return STATUS_CHIP.error;
      default:
        return STATUS_CHIP?.warning;
    }
  };

  const errors = shipmentDetails?.validationErrorMsg || [];

  if (shipmentDetails?.shipmentCreationErrorMsg) {
    errors.push(shipmentDetails?.shipmentCreationErrorMsg);
  }

  return (
    <>
      <Box>
        <Filters onFilterSubmit={handleFilterSubmit} />
      </Box>
      <CustomPagination
        page={page}
        totalCount={shipmentData?.totalCount || 0}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            border: `1px solid ${theme.palette.grey["400"]}`,
            borderRadius: 1,
            padding: 3,
          }}
        >
          <ShipmentDetails shipmentDetails={shipmentDetails} />
          <ShipmentLineItem shipmentDetails={shipmentDetails} />
          <ShipmentInvoiceDetails shipmentDetails={shipmentDetails} />
          <Divider style={{ marginTop: 10 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: !!errors?.length ? "space-between" : "flex-end",
            }}
            gap={2}
            mt={4}
          >
            <>
              {!!errors?.length && (
                <Box
                  style={{
                    padding: 0,
                    backgroundColor: theme.palette.error.errorBgColor,
                    width: "65%",
                    color: theme.palette.error.light,
                  }}
                >
                  <Box
                    style={{
                      display: "block",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                      maxWidth: "60vw",
                    }}
                  >
                    <Box
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      {errors?.map((error, i) => {
                        return (
                          <Box
                            style={{
                              backgroundColor:
                                error?.type === "warning" && "yellow",
                              padding: 6,
                              paddingLeft: 2,
                              paddingRight: 4,
                              marginLeft: 8,
                            }}
                          >
                            <Typography variant="body2" key={error}>
                              {`${error}`}
                              {errors?.length - 1 !== i && (
                                <span>&nbsp;&nbsp;&nbsp;|</span>
                              )}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              )}
            </>
            {shipmentDetails?.status === SHIPMENT_STATUS?.pending ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
                gap={2}
                mt={4}
              >
                <ButtonV1
                  title="Reject shipment"
                  variant="outlined"
                  color="buttonError"
                  onClick={() => handleShipment("REJECT")}
                />
                <ButtonV1
                  title="Approve shipment"
                  onClick={() => handleShipment("APPROVE")}
                />
              </Box>
            ) : (
              <StatusChip
                label={pascalCase(shipmentDetails?.status)}
                type={getStatusType(shipmentDetails?.status)}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ShipmentCreationJodl;
